<!-- src/components/UserLogin.vue -->

<template>
  <v-container class="login-container" fluid>
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" sm="8" md="3">
        <v-card class="elevation-12">
          <v-toolbar class="header" flat>
            <v-toolbar-title align="center">Happmobi</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-img :src="require('@/assets/logo.png')" alt="Logo Happmobi" class="logo" contain></v-img>
            <v-btn @click="loginWithSSO" class="button" block>Acessar</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
// import api from '@/utils/api';

export default {
  name: 'UserLogin',
  methods: {
    loginWithSSO() {
      window.open('/api/auth/sso', '_self');
    }
  }
}
</script>

<style scoped>
.logo {
  width: 35%;
  margin: 50px auto;
  display: block;
}
.login-container {
    display: flex;
    justify-content: center; /* Alinha horizontalmente ao centro */
    align-items: center;     /* Alinha verticalmente ao centro */
    height: 100vh;           /* 100% da altura da viewport */
}

</style>
