import { createRouter, createWebHistory } from 'vue-router';
import FileUpload from '@/components/LoggedIn/FileUpload.vue'
import FileDownload from '@/components/LoggedIn/FileDownload.vue'
import Login from '@/components/UserLogin.vue'
import api from '@/utils/api';
import { useToast } from 'vue-toastification';

const routes = [
  // ... suas outras rotas
    {
        path: '/',
        name: 'Login',
        component: Login
    },{
        path: '/area-logada/logon',
        name: 'Logon',
        // registra no localsotrage o token de acesso
        beforeEnter: (to, from, next) => {
            localStorage.setItem('access-token', to.query.token)
            api.update()
            next({ name: 'FileUpload' })
        }
    },
    {
        path: '/access/:page/:id',
        name: 'Access',
        // registra no localsotrage o token de acesso
        beforeEnter: (to, from, next) => {
            if(to.query.token && !localStorage.getItem('access-token')){
                localStorage.setItem('access-token', to.query.token)
                api.update()
            }
            next({ path: `${to.params.page}/${to.params.id}` })
        }
    },
    {
        path: '/area-logada/upload',
        name: 'FileUpload',
        component: FileUpload,
        meta: { requiresAuth: true, roles: ['contributor', 'admin'] }
    },
    {
        path: '/download/:id',
        name: 'FileDownload',
        component: FileDownload,
        meta: { requiresAuth: true }
    },
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
  });

// Verificação de autenticação para rotas que a requerem
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const toast = useToast();
    if (localStorage.getItem('access-token')) {
        api.post('auth/validate').then((response) => {
            if (response.data.success && (!to.meta?.roles || response.data.roles.some(role => to.meta?.roles?.includes(role)))) {
                    next()
            } else {
                next({ name: 'Login' })   
                toast.error('Sessão expirada, faça login novamente');
            }
        }).catch(() => {
            toast.error('Erro ao logar, faça login novamente');
            next({ name: 'Login' })
        })
    } else {
        toast.error('Erro ao logar, faça login novamente');
        next({ name: 'Login' }) // permite o acesso à rota
    }
  } else {
    next() // permite o acesso à rota
  }
})

export default router
