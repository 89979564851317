<template>
  <v-container fluid class="upload-container">
    <v-row class="fill-height" justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-toolbar class="header" flat>
            <v-toolbar-title align="center">Upload</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-file-input
              v-model="file"
              :multiple="true"
              label="Escolha seu(s) arquivo(s)"
              prepend-icon="mdi-upload"
              outlined
              :show-size="1000"
            ></v-file-input>
            <v-checkbox 
              v-model="compress"
              label="Compactar em um único arquivo"
              prepend-icon="mdi-group"
              outlined
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button" :disabled="btEnabled === false" text @click="uploadFile">Enviar</v-btn>
          </v-card-actions>
          <!-- Result -->
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" >
                <v-alert
                  v-if="result"
                  :value="true"
                  type="success"
                  dismissible
                  @input="result = false"
                >
                <v-btn
                    color="primary"
                    text
                    @click="copyToClipboard(result.data.url)"
                    prepend-icon="mdi-content-copy"
                  >Copiar</v-btn>
                  <p>{{ result.data.url }}</p>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/utils/api";
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      file: null,
      compress: false,
      btEnabled: true,
      result: null
    };
  },
  methods: {
    uploadFile() {
      if (!this.file) {        
        const toast = useToast();
        toast.error("Por favor, selecione um arquivo antes de fazer o upload.");
        return;
      }
      // disable button
      this.btEnabled = false;
      const formData = new FormData();
      for (let i = 0; i < this.file.length; i++) {
        formData.append("file", this.file[i]);
      }
      //formData.append("compress", this.compress.toString());
      api.post(`files/upload?compress=${this.compress}`, formData)
        .then((response) => {
          this.result = response.data;
          const toast = useToast();
          toast.success("Upload realizado com sucesso!");
        })
        .catch(() => {
          const toast = useToast();
          toast.error("Ocorreu um erro ao fazer o upload.");
        }).finally(() => {
          this.btEnabled = true;
        })
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      const toast = useToast();
      toast.success("Link copiado para a área de transferência.");
    }

  }
};
</script>

<style scoped>
.upload-container {
  height: calc(100vh - 64px);
}
</style>
