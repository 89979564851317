<template>
<v-container fluid class="download-container">
    <v-row class="fill-height" justify="center" align="center" :style="cssProps">
      <v-col cols="6" md="5">
        <v-card class="pa-5" elevation="12">
          <v-card-title>
            Lista de Arquivos para Download
          </v-card-title>
          <v-divider></v-divider>
          <v-list>
              <v-list-item v-for="file in files" :key="file.id">
                  <v-row no-gutters align="center">
                    <v-col class="shrink" md="1">
                      <v-btn target="_blank" :name="file.filename" @click="handleClick(file.filename)" icon small class="button" >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-col>                    
                    <v-col class="flex-grow-1" align="left" md="10">
                        <v-btn md="3" text target="_blank" @click="handleClick(file.filename)" :name="file.filename" class="text-left ml-3" style="justify-content: start;">
                            {{ decodeURI( file.filename ) }}
                        </v-btn>
                        <v-icon  class="ml-3"  icon small v-if="file.downloaded === true">mdi-check</v-icon>
                    </v-col>                    
                  </v-row>
              </v-list-item>
              <v-divider></v-divider>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
</v-container>
  </template>
<script>
    import { decodeURI } from '@/utils/filters';
    import api from '@/utils/api';
    export default {
        name: 'FileDownload',
        filters: {
            decodeURI
        },
        created() {
            const id = this.$route.params.id;
            api.get('files', {
                params: {
                    id: id,
                }
            }).then((response) => {
                this.id = response.data.id;
                this.files = response.data.files;

                if(response.data.cssProps) {
                  this.cssProps = response.data.cssProps;
                }

            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                console.log('finally');
            });
        },
        methods: {
            handleClick(btName) {
              api.get(`files/download/${this.id}`, { params: { filename: btName}}).then((response) => {
                window.open(response.data, '_blank');
              }).catch((error) => {
                console.log(error);
              }).finally(() => {
                console.log('finally');
              });
              this.showCheckDownloaded(btName)        
            },
            showCheckDownloaded(btName){
                this.files.forEach(element => {
                    if(element.filename === btName){
                        element.downloaded = true;
                    }
                });
            }
            
        },
       
        data() {
            return {
                id: null,
                files: [                    
                ],
                background: 'background.png',
                cssProps: {
                }
            };
        }
    };
</script>
  <style scoped>
  .v-card {
    background-color: rgba(255, 255, 255, 0.8); /* Fundo branco com uma leve transparência */
  }
.download-container {
  height: calc(100vh - 64px);
  .button {
    margin: 10px;
    color: #FFF;
    background-color: primary;
  }
}
  </style>
  