import axios from 'axios';

let api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 360000,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access-token'),
    }
});

api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access-token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });
  
const update = () => {
    api = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        timeout: 60000,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access-token'),
        }
    });
    api.update = update
}

api.update = update


export default api;