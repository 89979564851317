<template>
  <v-app>
    <div id="app">
        <v-app-bar app v-if="logged">
          <v-container class="logo-container" contain>
            <v-img :src="require('@/assets/logo.png')" alt="Logo Happmobi" contain></v-img>
          </v-container>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="logout">Logout</v-btn>
        </v-app-bar>
        <v-main>
          <router-view></router-view>
        </v-main>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      logged: false
    }
  },
  created() {
    if (localStorage.getItem('access-token')) {
      this.logged = true;
    }
  },
  updated() {
    if (localStorage.getItem('access-token')) {
      this.logged = true;
    }
  },
  watch:{
    $route (){
      if (localStorage.getItem('access-token') && this.$route.path !== '/') {
        this.logged = true;
      }else{
        this.logged = false;
      }
    }
  },
  methods: {
    logout() {
      this.logged = false;
      localStorage.clear();
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.logo-container {
  height: 100%;
  display: block;
  width: 100px;
  padding: 5px;
}
</style>
